<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-form
        ref="form"
        @submit.prevent="save()"
        v-model="valid"
        action="/"
        v-if="!spinnerActive"
      >
        <div style="height: calc(100vh - 50px);">
          <v-card v-if="website_affair_content">
            <v-card-title>
              <span class="text-h5">Medical affairs page</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit.prevent="save()"
              action="/"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      :rules="[rules.required]"
                      v-model="website_affair_content.medical_affairs_title"
                      label="Medial affairs title"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="website_affair_content.tab_title"
                          label="SEO - Page title (shown in browser tab)"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span>SEO Page title - This is the title of the tab of the page and also shows up
                        <br>in search engines as the main title for the page.
                        <br>Keep the title short (max. 60 characters) and
                        <br>utilise keywords from the page.
                      </span>
                    </v-tooltip>
                  </v-row>
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                      <v-textarea
                        v-model="website_affair_content.og_meta_desc"
                        v-bind="attrs"
                        v-on="on"
                        label="SEO - Meta description (this description will appear on google search results)"
                      ></v-textarea>
                      </template>
                      <span>The meta description is shown in search engines and
                        <br>is used to describe the contents of the page.
                        <br>Keep it between 50-160 characters and try to
                        <br>imagine what a user would search for in order
                        <br>to find the contents of the page.
                      </span>
                    </v-tooltip>
                  </v-row>
                  <v-row>
                    <span style="width: 100%" class="mt-8">Medial affairs description</span>
                    <editor
                      style="width: 100%"
                      v-if="editorReady"
                      v-model="website_affair_content.medical_affairs_description"
                      api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                      :disabled="false"
                      initial-value=""
                      :init="{
                        images_upload_url: `${baseURL}/api/image-upload`,
                        height: 500,
                        menubar: false,
                        document_base_url: `${baseURL}/`,
                        relative_urls: false,
                        remove_script_host: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help \
                          link \
                          media image',
                      }"
                    />
                  </v-row>
                  <div style="width: 100%" class="mt-16"></div>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <div style="width:100%; height:40px"></div>
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!medical_affairs_image_1 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="website_affair_content.medical_affairs_image_1"
                        :input="medicalAffairsLoadImage_1()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="medical_affairs_image_1"
                          :src="getImage(medical_affairs_image_1)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="website_affair_content.alt_image_1"
                              label="SEO - Alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!medical_affairs_image_2 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="website_affair_content.medical_affairs_image_2"
                        :input="medicalAffairsLoadImage_2()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="medical_affairs_image_2"
                          :src="getImage(medical_affairs_image_2)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="website_affair_content.alt_image_2"
                              label="SEO - Alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!medical_affairs_image_3 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="website_affair_content.medical_affairs_image_3"
                        :input="medicalAffairsLoadImage_3()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="medical_affairs_image_3"
                          :src="getImage(medical_affairs_image_3)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="website_affair_content.alt_image_3"
                              label="SEO - Alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!medical_affairs_image_4 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="website_affair_content.medical_affairs_image_4"
                        :input="medicalAffairsLoadImage_4()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="medical_affairs_image_4"
                          :src="getImage(medical_affairs_image_4)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="website_affair_content.alt_image_4"
                              label="SEO - Alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!medical_affairs_image_5 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="website_affair_content.medical_affairs_image_5"
                        :input="medicalAffairsLoadImage_5()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="medical_affairs_image_5"
                          :src="getImage(medical_affairs_image_5)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="website_affair_content.alt_image_5"
                              label="SEO - Alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <div style="width:100%; height:40px"></div>
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!medical_affairs_image_6 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="website_affair_content.medical_affairs_image_6"
                        :input="medicalAffairsLoadImage_6()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="medical_affairs_image_6"
                          :src="getImage(medical_affairs_image_6)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="website_affair_content.alt_image_6"
                              label="SEO - Alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span v-if="successfullyUpdated" style="color:green; margin-right:10px">Successfully updated!</span>
                <v-btn color="blue darken-1" text type="submit">Save</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
        <!-- <div class="mt-1 save-button-wrapper"> -->
          <!-- <v-btn color="primary" elevation="2" type="submit" :disabled="spinnerActive">Save</v-btn> -->
        <!-- </div> -->
      </v-form>
    </div>
  </div>
</template>

<script>
import { fetchMedicalAffairsPage, updateMedicalAffairsPage } from "@/services";
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "MedicalAffairsPage",
  components: {
    TheSidebar,
  },
  data () {
    return {
      successfullyUpdated: false,
      website_affair_content: null,
      spinnerActive: false,
      valid: false,
      editorReady: true,
      baseURL: '',
      medical_affairs_image_1: null,
      medical_affairs_image_2: null,
      medical_affairs_image_3: null,
      medical_affairs_image_4: null,
      medical_affairs_image_5: null,
      medical_affairs_image_6: null,
      fileName_ma_1: null,
      fileName_ma_2: null,
      fileName_ma_3: null,
      fileName_ma_4: null,
      fileName_ma_5: null,
      fileName_ma_6: null,
      imageUpdated_ma_1: false,
      imageUpdated_ma_2: false,
      imageUpdated_ma_3: false,
      imageUpdated_ma_4: false,
      imageUpdated_ma_5: false,
      imageUpdated_ma_6: false,
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  mounted() {
    this.baseURL = window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz' : window.location.origin
    // this.getItems()
    this.getMedicalAffairsPage()
  },
  methods: {
    // async getItems() {
    //   await fetchAllMedicalAffairs().then(response => {
    //     console.log('response', response)
    //   })
    // },
    async getMedicalAffairsPage() {
      await fetchMedicalAffairsPage().then(response => {
        this.website_affair_content = response.data.data
        if (this.website_affair_content.medical_affairs_image_1) {
          this.medical_affairs_image_1 = this.website_affair_content.medical_affairs_image_1
          this.website_affair_content.medical_affairs_image_1 = null 
        }
        if (this.website_affair_content.medical_affairs_image_2) {
          this.medical_affairs_image_2 = this.website_affair_content.medical_affairs_image_2
          this.website_affair_content.medical_affairs_image_2 = null 
        }
        if (this.website_affair_content.medical_affairs_image_3) {
          this.medical_affairs_image_3 = this.website_affair_content.medical_affairs_image_3
          this.website_affair_content.medical_affairs_image_3 = null 
        }
        if (this.website_affair_content.medical_affairs_image_4) {
          this.medical_affairs_image_4 = this.website_affair_content.medical_affairs_image_4
          this.website_affair_content.medical_affairs_image_4 = null 
        }
        if (this.website_affair_content.medical_affairs_image_5) {
          this.medical_affairs_image_5 = this.website_affair_content.medical_affairs_image_5
          this.website_affair_content.medical_affairs_image_5 = null 
        }
        if (this.website_affair_content.medical_affairs_image_6) {
          this.medical_affairs_image_6 = this.website_affair_content.medical_affairs_image_6
          this.website_affair_content.medical_affairs_image_6 = null 
        }
      }).catch(error => {
        console.log('error', error)
      })
    },
    medicalAffairsLoadImage_1() {
      if (this.website_affair_content && this.website_affair_content.medical_affairs_image_1 && this.website_affair_content.medical_affairs_image_1.size) {
        var file = this.website_affair_content.medical_affairs_image_1
        var reader = new FileReader()
        this.fileName_ma_1 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_1 = reader.result
            this.imageUpdated_ma_1 = true
            this.website_affair_content.medical_affairs_image_1 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_2() {
      if (this.website_affair_content && this.website_affair_content.medical_affairs_image_2 && this.website_affair_content.medical_affairs_image_2.size) {
        var file = this.website_affair_content.medical_affairs_image_2
        var reader = new FileReader()
        this.fileName_ma_2 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_2 = reader.result
            this.imageUpdated_ma_2 = true
            this.website_affair_content.medical_affairs_image_2 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_3() {
      if (this.website_affair_content && this.website_affair_content.medical_affairs_image_3 && this.website_affair_content.medical_affairs_image_3.size) {
        var file = this.website_affair_content.medical_affairs_image_3
        var reader = new FileReader()
        this.fileName_ma_3 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_3 = reader.result
            this.imageUpdated_ma_3 = true
            this.website_affair_content.medical_affairs_image_3 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_4() {
      if (this.website_affair_content && this.website_affair_content.medical_affairs_image_4 && this.website_affair_content.medical_affairs_image_4.size) {
        var file = this.website_affair_content.medical_affairs_image_4
        var reader = new FileReader()
        this.fileName_ma_4 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_4 = reader.result
            this.imageUpdated_ma_4 = true
            this.website_affair_content.medical_affairs_image_4 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_5() {
      if (this.website_affair_content && this.website_affair_content.medical_affairs_image_5 && this.website_affair_content.medical_affairs_image_5.size) {
        var file = this.website_affair_content.medical_affairs_image_5
        var reader = new FileReader()
        this.fileName_ma_5 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_5 = reader.result
            this.imageUpdated_ma_5 = true
            this.website_affair_content.medical_affairs_image_5 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_6() {
      if (this.website_affair_content && this.website_affair_content.medical_affairs_image_6 && this.website_affair_content.medical_affairs_image_6.size) {
        var file = this.website_affair_content.medical_affairs_image_6
        var reader = new FileReader()
        this.fileName_ma_6 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_6 = reader.result
            this.imageUpdated_ma_6 = true
            this.website_affair_content.medical_affairs_image_6 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
    async save() {
      let data = Object.assign({}, this.website_affair_content)
      if (this.imageUpdated_ma_1) {
        data.medical_affairs_image_1 = this.medical_affairs_image_1
        data["file_name_1"] = this.fileName_ma_1
      }
      if (this.imageUpdated_ma_2) {
        data.medical_affairs_image_2 = this.medical_affairs_image_2
        data["file_name_2"] = this.fileName_ma_2
      }
      if (this.imageUpdated_ma_3) {
        data.medical_affairs_image_3 = this.medical_affairs_image_3
        data["file_name_3"] = this.fileName_ma_3
      }
      if (this.imageUpdated_ma_4) {
        data.medical_affairs_image_4 = this.medical_affairs_image_4
        data["file_name_4"] = this.fileName_ma_4
      }
      if (this.imageUpdated_ma_5) {
        data.medical_affairs_image_5 = this.medical_affairs_image_5
        data["file_name_5"] = this.fileName_ma_5
      }
      if (this.imageUpdated_ma_6) {
        data.medical_affairs_image_6 = this.medical_affairs_image_6
        data["file_name_6"] = this.fileName_ma_6
      }
      await updateMedicalAffairsPage(data).then(() => {
        this.successfullyUpdatedTrigger()
        this.getMedicalAffairsPage()
      })
    },
    successfullyUpdatedTrigger() {
      this.successfullyUpdated = true
      setTimeout(() => {
        this.successfullyUpdated = false
      }, 5000);
    }
  }
}
</script>
<style scoped>
.save-button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>