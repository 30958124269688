var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('TheSidebar'),_c('div',{staticClass:"right-content-wrapper"},[(!_vm.spinnerActive)?_c('v-form',{ref:"form",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticStyle:{"height":"calc(100vh - 50px)"}},[(_vm.website_affair_content)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Medical affairs page")])]),_c('v-form',{ref:"form",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":"Medial affairs title"},model:{value:(_vm.website_affair_content.medical_affairs_title),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_title", $$v)},expression:"website_affair_content.medical_affairs_title"}})],1),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Page title (shown in browser tab)"},model:{value:(_vm.website_affair_content.tab_title),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "tab_title", $$v)},expression:"website_affair_content.tab_title"}},'v-text-field',attrs,false),on))]}}],null,false,2320561853)},[_c('span',[_vm._v("SEO Page title - This is the title of the tab of the page and also shows up "),_c('br'),_vm._v("in search engines as the main title for the page. "),_c('br'),_vm._v("Keep the title short (max. 60 characters) and "),_c('br'),_vm._v("utilise keywords from the page. ")])])],1),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"label":"SEO - Meta description (this description will appear on google search results)"},model:{value:(_vm.website_affair_content.og_meta_desc),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "og_meta_desc", $$v)},expression:"website_affair_content.og_meta_desc"}},'v-textarea',attrs,false),on))]}}],null,false,2128905981)},[_c('span',[_vm._v("The meta description is shown in search engines and "),_c('br'),_vm._v("is used to describe the contents of the page. "),_c('br'),_vm._v("Keep it between 50-160 characters and try to "),_c('br'),_vm._v("imagine what a user would search for in order "),_c('br'),_vm._v("to find the contents of the page. ")])])],1),_c('v-row',[_c('span',{staticClass:"mt-8",staticStyle:{"width":"100%"}},[_vm._v("Medial affairs description")]),(_vm.editorReady)?_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
                      images_upload_url: (_vm.baseURL + "/api/image-upload"),
                      height: 500,
                      menubar: false,
                      document_base_url: (_vm.baseURL + "/"),
                      relative_urls: false,
                      remove_script_host: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount' ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help \
                        link \
                        media image',
                    }},model:{value:(_vm.website_affair_content.medical_affairs_description),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_description", $$v)},expression:"website_affair_content.medical_affairs_description"}}):_vm._e()],1),_c('div',{staticClass:"mt-16",staticStyle:{"width":"100%"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticStyle:{"width":"100%","height":"40px"}}),_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.medical_affairs_image_1 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.medicalAffairsLoadImage_1(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.website_affair_content.medical_affairs_image_1),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_image_1", $$v)},expression:"website_affair_content.medical_affairs_image_1"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.medical_affairs_image_1)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.medical_affairs_image_1),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Alt image"},model:{value:(_vm.website_affair_content.alt_image_1),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "alt_image_1", $$v)},expression:"website_affair_content.alt_image_1"}},'v-text-field',attrs,false),on))]}}],null,false,2958423399)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.medical_affairs_image_2 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.medicalAffairsLoadImage_2(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.website_affair_content.medical_affairs_image_2),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_image_2", $$v)},expression:"website_affair_content.medical_affairs_image_2"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.medical_affairs_image_2)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.medical_affairs_image_2),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Alt image"},model:{value:(_vm.website_affair_content.alt_image_2),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "alt_image_2", $$v)},expression:"website_affair_content.alt_image_2"}},'v-text-field',attrs,false),on))]}}],null,false,3078323972)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1),_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.medical_affairs_image_3 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.medicalAffairsLoadImage_3(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.website_affair_content.medical_affairs_image_3),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_image_3", $$v)},expression:"website_affair_content.medical_affairs_image_3"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.medical_affairs_image_3)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.medical_affairs_image_3),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Alt image"},model:{value:(_vm.website_affair_content.alt_image_3),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "alt_image_3", $$v)},expression:"website_affair_content.alt_image_3"}},'v-text-field',attrs,false),on))]}}],null,false,12108965)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.medical_affairs_image_4 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.medicalAffairsLoadImage_4(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.website_affair_content.medical_affairs_image_4),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_image_4", $$v)},expression:"website_affair_content.medical_affairs_image_4"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.medical_affairs_image_4)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.medical_affairs_image_4),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Alt image"},model:{value:(_vm.website_affair_content.alt_image_4),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "alt_image_4", $$v)},expression:"website_affair_content.alt_image_4"}},'v-text-field',attrs,false),on))]}}],null,false,3440366914)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1),_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.medical_affairs_image_5 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.medicalAffairsLoadImage_5(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.website_affair_content.medical_affairs_image_5),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_image_5", $$v)},expression:"website_affair_content.medical_affairs_image_5"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.medical_affairs_image_5)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.medical_affairs_image_5),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Alt image"},model:{value:(_vm.website_affair_content.alt_image_5),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "alt_image_5", $$v)},expression:"website_affair_content.alt_image_5"}},'v-text-field',attrs,false),on))]}}],null,false,225908963)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticStyle:{"width":"100%","height":"40px"}}),_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.medical_affairs_image_6 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.medicalAffairsLoadImage_6(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.website_affair_content.medical_affairs_image_6),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "medical_affairs_image_6", $$v)},expression:"website_affair_content.medical_affairs_image_6"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.medical_affairs_image_6)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.medical_affairs_image_6),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Alt image"},model:{value:(_vm.website_affair_content.alt_image_6),callback:function ($$v) {_vm.$set(_vm.website_affair_content, "alt_image_6", $$v)},expression:"website_affair_content.alt_image_6"}},'v-text-field',attrs,false),on))]}}],null,false,345809536)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.successfullyUpdated)?_c('span',{staticStyle:{"color":"green","margin-right":"10px"}},[_vm._v("Successfully updated!")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit"}},[_vm._v("Save")])],1)],1)],1):_vm._e()],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }